import './mission-page.scss';
import { useState, useEffect } from 'react';
import LinkComponent from '../../components/Link/Link.component.jsx';

import NavigationBar from '../../components/Navigation/NavigationBar';
import { Images } from '../../utils/imagesPath.js';
import FooterUI from '../../ui/footer/footer.ui';

const MissionPage = () => {
  const { mission, missionLarge } = Images;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      const maxWidthForMobile = 768;
      setIsMobile(window.innerWidth < maxWidthForMobile);
    };

    // Check on mount
    checkIfMobile();

    // Add event listener to check on resize
    window.addEventListener('resize', checkIfMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <>
      <NavigationBar />
      <main className='mission-page'>
        <div className='mission-page__link'>
          <LinkComponent to={`/`} classStyle={'left'} children={'Acasă'} />
        </div>

        <img
          src={isMobile ? mission : missionLarge}
          alt='Two pencils on a yellow background'
          className='mission-page__image'
        />

        <h1>Misiunea noastra</h1>
        <section className='mission-page__text'>
          <p>
            Misiunea noastră este de a oferi oportunități de educație și
            dezvoltare copiilor din medii defavorizate, pentru a le oferi șanse
            egale în viață. Toţi cetăţenii români au drept egal la educaţie, la
            toate nivelurile şi toate formele, indiferent de gen, rasă,
            naţionalitate, religie sau afiliere politică şi indiferent de
            statutul social sau economic. Acest drept este prevăzut în Legea
            învăţământului nr. 84/1995. Din nefericire școala românească se
            confruntă cu probleme legate de şcolarizarea copiilor defavorizaţi.
            În categoria copiilor defavorizaţi sunt incluși cei proveniţi din
            familii monoparentale sau orfani de ambii părinţi, copiii ai căror
            părinţi sunt şomeri sau sunt plecaƫi în strāinatate fiind lāsaƫi în
            grija bunicilor sau a rudelor.
          </p>
          <p>
            Pentru a aborda aceste probleme, este esențial să dezvoltăm programe
            și inițiative care să sprijine acești copii în mod direct. Aceste
            programe pot include suport psihologic și consiliere, activități
            extracurriculare care să le dezvolte abilitățile și talentele,
            precum și acces la resurse educaționale adecvate. De asemenea,
            colaborarea cu organizații non-guvernamentale și comunitățile locale
            poate juca un rol crucial în crearea unui mediu de sprijin și
            încurajare pentru acești copii. Este vital ca societatea să
            recunoască importanța integrării copiilor defavorizați în sistemul
            educațional și să lucreze împreună pentru a se asigura că fiecare
            copil are șansa de a-și atinge potențialul maxim, indiferent de
            circumstanțele lor personale. Numai prin aceste eforturi concentrate
            putem contribui la construirea unui viitor mai echitabil și mai
            prosper pentru toți.
          </p>
        </section>
      </main>
      <FooterUI />
    </>
  );
};

export default MissionPage;

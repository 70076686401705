import './mission.styles.scss';
import LinkComponent from '../../components/Link/Link.component.jsx';
import { Images } from '../../utils/imagesPath.js';

const MissionUI = () => {
  const { mission } = Images;

  return (
    <div className='mission-ui'>
      <div className='mission-ui__content'>
        <h2 className='h h--xl'>Misiunea noastră</h2>
        <p>
          Este de a oferi oportunități de educație și dezvoltare copiilor din
          medii defavorizate, pentru a le oferi șanse egale în viață.
        </p>
        <LinkComponent
          to='/misiunea-noastra'
          classStyle={'right'}
          children={'Află mai multe'}
        />
      </div>
      <div className='mission-ui__image'>
        <img
          src={mission}
          alt='Two penciels on a yellow background'
          className='mission-ui__image--img'
        />
      </div>
    </div>
  );
};

export default MissionUI;

import './charityAction.styles.scss';

const CharityAction = () => {
  return (
    <div className='charity-action'>
      <h2 className='h h--xl'>
        <span>Charity action</span> pentru educație
      </h2>
      <div className='charity-action__container'>
        <p>
          Pentru a preveni si a reduce abandonul scolar sau marginalizarea
          copiilor cu posibilitati financiare reduse, noi si echipa noastra de
          profesori oferim ajutor gratuit pentru toate materiile unde copiii au
          nevoie de ajutor, iar in acest moment sunt aproximativ 50 de copilasi
          inscrisi la noi si numarul este in crestere.
        </p>
        <p>
          O data cu inceperea anului scolar am initializat programul “CHARITY
          ACTION” PENTRU EDUCATIE cu scopul de a ajuta acesti copii invitandu-va
          sa va implicati (prin donatii, sponsorizari...) alaturi de noi in
          sustinerea educatiei lor.{' '}
        </p>

        <h4>
          Numarul copiilor ce au nevoie de ajutor creste in fiecare zi si
          resursele noastre deja sunt prea putine.
        </h4>

        <p>
          Pentru a ne ajuta si a-i ajuta, puteti face donatii direct pe site-ul
          nostru (specificand "DONATIE"), puteti face sponsorizari sau ne puteti
          contacta daca exista bunuri materiale spre donatie precum produse de
          papetarie necesare elevilor, tablete...sau altceva ce doriti dona
          pentru a-i sprijini. Ne puteti contacta pentru a gasi solutii impreuna
          astfel incat sa putem face viata mai usoara acestor copilasi!
        </p>
      </div>
    </div>
  );
};

export default CharityAction;

export const objectives = [
  {
    id: 1,
    title: 'Crearea unei Rețele Naționale de Centre Educaționale',
    description:
      'Vrem să deschidem centre educaționale în fiecare regiune a țării, pentru a oferi acces la resurse educaționale tuturor copiilor, indiferent de locație',
  },
  {
    id: 2,
    title: 'Dezvoltarea unui Program de Mentorat la Nivel Național',
    description:
      'Intenționăm să implementăm un program de mentorat pentru a conecta copii fără posibilități cu mentori din diverse domenii, care să le ofere ghidare și inspirație pentru carierele lor viitoare.',
  },
  {
    id: 3,
    title: 'Promovarea Schimbării Politicilor Educaționale',
    description:
      'Vom lucra pentru a influența și reforma politicile educaționale, astfel încât să asigurăm un sistem educațional mai echitabil și incluziv pentru toți copiii.',
  },
  {
    id: 4,
    title: 'Creșterea Gradului de Conștientizare și Implicare Comunitară',
    description:
      'Vom lansa campanii naționale de conștientizare pentru a educa publicul larg despre importanța sprijinirii educației copiilor defavorizați și pentru a încuraja participarea activă a comunității.',
  },
  {
    id: 5,
    title: 'Parteneriate Strategice cu Instituții Educaționale și Corporative',
    description:
      'Vom stabili colaborări durabile cu universități, școli și companii pentru a dezvolta programe de internship, burse și oportunități de formare profesională pentru tinerii noștri.',
  },
  {
    id: 6,
    title: 'Implementarea de Tehnologii Inovative în Educație',
    description:
      'Vom investi în tehnologii educaționale moderne, precum platforme de e-learning și resurse digitale, pentru a oferi o educație de calitate și accesibilă tuturor copiilor.',
  },
  {
    id: 7,
    title: 'Organizarea de Evenimente Educaționale și Conferințe',
    description:
      'Vom organiza conferințe, seminarii și evenimente educaționale pentru profesori, elevi și părinți, promovând cele mai bune practici în educație și facilitând schimbul de cunoștințe și idei între participanți.',
  },
  {
    id: 8,
    title: 'Crearea de Rețele de Suport pentru Părinți',
    description:
      'Vom iniția grupuri de suport și programe de formare pentru părinți, ajutându-i să înțeleagă și să sprijine mai bine educația copiilor lor, promovând astfel un mediu familial favorabil învățării.',
  },
  {
    id: 9,
    title:
      'Promovarea Educației Non-Formale și a Învățării Pe Tot Parcursul Vieții',
    description:
      'Vom organiza activități educaționale non-formale, precum tabere de vară, ateliere creative și evenimente culturale, pentru a stimula creativitatea și pasiunea pentru învățare la copiii noștri.',
  },
];

import './article.styles.scss';

import { articles } from '../../utils/articles';
import LinkComponent from '../../components/Link/Link.component';
import ArticleCard from '../../components/Article-card/ArticleCard.component';

const ArticleUI = () => {
  return (
    <div className='article-ui'>
      {/* <h1 className='article-ui__title'>Articole</h1> */}
      <h2 className='h h--xl'>Articole</h2>
      <div className='article-ui__container'>
        {articles.slice(0, 3).map((article) => {
          return <ArticleCard key={article.title} article={article} />;
        })}
      </div>
      <LinkComponent
        to={'/articole'}
        classStyle={'right'}
        children={'Vezi mai multe articole'}
      />
    </div>
  );
};

export default ArticleUI;

import React, { useEffect, useState, useRef } from 'react';
import './counter-item.styles.scss';

const CounterItem = ({ target, label }) => {
  const [count, setCount] = useState(0);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            let start = 0;
            const increment = target / 50;

            const updateCount = () => {
              start += increment;
              if (start < target) {
                setCount(Math.ceil(start));
                requestAnimationFrame(updateCount);
              } else {
                setCount(target);
              }
            };

            requestAnimationFrame(updateCount);
          }
        });
      },
      {
        root: null, // observing for viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger if at least 10% of the item is visible
      }
    );

    const currentRef = counterRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [target]);

  return (
    <div className='counter-item' ref={counterRef}>
      <div className='counter-item__number'>{count}+</div>
      <hr />
      <div className='counter-item__text'>{label}</div>
    </div>
  );
};

export default CounterItem;

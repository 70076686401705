import './vision-page.scss';

import LinkComponent from '../../components/Link/Link.component';
import NavigationBar from '../../components/Navigation/NavigationBar';
import FooterUI from '../../ui/footer/footer.ui';

import { Images } from '../../utils/imagesPath.js';
import { objectives } from '../../utils/objectives.js';

const VisionPage = () => {
  const { vision1, vision2, vision3 } = Images;

  return (
    <>
      <NavigationBar />
      <main className='vision-page'>
        <div className='vision-page__link'>
          <LinkComponent to={`/`} classStyle={'left'} children={'Acasă'} />
        </div>
        <div className='vision-page__welcome'>
          <div className='vision-page__welcome__text'>
            <h2>
              Ne dorim o lume în care <span>fiecare copil</span>,
            </h2>
            <p>
              indiferent de mediul din care provine, să aibă acces la o educație
              de calitate care să le deschidă noi oportunități și să le permită
              să-și atingă potențialul maxim.
            </p>
            <p>
              Viziunea noastră este de a oferi o educație de calitate pentru
              toți copiii, indiferent de situația lor socială sau financiară.
              Noi avem sarcina de ajuta acești copii sā se dezvolte armonios,
              sā-și descopere pasiuni și abilitāƫi care sā-i recomande pentru o
              profesie, pentru viata. Este necesar si moral realizarea unui
              cadru educativ incluziv, bazat pe egalitate de sanse, diversitate,
              toleranta, incluziune sociala si de a crea oportunităţi de
              învăţare pentru orice elev.
            </p>
          </div>
          <div className='vision-page__welcome__images'>
            <div className='vision-page__welcome__images--left'>
              <img
                src={vision1}
                alt='A child with a woman in a classroom'
                className='vision-page__image vision-page__image__big'
              />
            </div>
            <div className='vision-page__welcome__images--right'>
              <img
                src={vision2}
                alt='A child with a woman in a classroom'
                className='vision-page__image vision-page__image__small vision-page__image__small--up'
              />
              <img
                src={vision3}
                alt='A child with a woman in a classroom'
                className='vision-page__image vision-page__image__small vision-page__image__small--down'
              />
            </div>
          </div>
        </div>

        <div className='vision-page__objectives'>
          <h2>Obectivele noastre pe termen lung</h2>
          <div className='vision-page__objectives__container'>
            {objectives.map((objective, index) => (
              <div key={index} className='vision-page__objectives__box'>
                <h4>{`${objective.title}`}</h4>
                <p>{objective.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='vision-page__values'>
          <h2>Valorile fundamentale după care ne ghidăm</h2>
          <p>
            <span className='vision-page__values__checkmark'>&#10003;</span>{' '}
            <span className='vision-page__values__bold'>Egalitate</span>:
            Fiecare copil merită șanse egale la educație. Ne angajăm să oferim
            oportunități echitabile pentru toți, indiferent de situația lor
            economică.
          </p>
          <p>
            <span className='vision-page__values__checkmark'>&#10003;</span>{' '}
            <span className='vision-page__values__bold'>Integritate</span>:
            Transparența și onestitatea sunt pilonii acțiunilor noastre. Ne
            asumăm responsabilitatea pentru modul în care sunt utilizate
            fondurile și resursele.
          </p>
          <p>
            <span className='vision-page__values__checkmark'>&#10003;</span>{' '}
            <span className='vision-page__values__bold'>Inovație</span>:
            Investim în soluții creative și eficiente pentru a face educația
            accesibilă și relevantă pentru copiii din medii defavorizate.
          </p>
          <p>
            <span className='vision-page__values__checkmark'>&#10003;</span>{' '}
            <span className='vision-page__values__bold'>Compasiune</span>:
            Credem în puterea comunității și a empatiei. Ne dorim să creăm o
            rețea de sprijin și solidaritate în jurul fiecărui copil.
          </p>
        </div>
      </main>
      <FooterUI />
    </>
  );
};
export default VisionPage;

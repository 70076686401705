import './article-card.styles.scss';

export const ArticleCard = ({ article }) => {
  const handleClick = () => {
    const slug = article.slug;
    window.location.href = `/articole/${slug}`;
  };

  return (
    <div key={article.title} className='article-card'>
      <img
        src={article.imageSmall}
        alt={article.title}
        className='article-card__image'
      />
      <div className='article-card__content'>
        <h3 className='article-card__content__title'>{article.title}</h3>
        <div className='article-card__content__description'>
          <p>
            {article.description[0].length > 60
              ? article.description[0].substring(0, 60) + '...'
              : article.description[0]}
          </p>
        </div>
        <button className='article-card__content__button' onClick={handleClick}>
          Vezi articolul
        </button>
      </div>
    </div>
  );
};

export default ArticleCard;

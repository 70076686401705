import './scope.styles.scss';
import { scope } from '../../utils/scope';

const ScopeUI = () => (
  <div className='scope-ui'>
    <div className='scope-ui__container'>
      <h2 className='h h--xl'>Scopul activității noastre</h2>
      {scope.map((item) => {
        return (
          <div key={item.id} className='scope-ui__box'>
            <span>&#10003;</span>
            <p>{item.text}</p>
          </div>
        );
      })}
    </div>
  </div>
);

export default ScopeUI;

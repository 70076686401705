export const articles = [
  {
    imageSmall: './../images/articles/article--1-small.webp',
    imageLarge: './../images/articles/article--1-large.webp',
    title: 'Cu cat timp înainte de examen trebuie să înveți?',
    slug: 'cu-cat-timp-înainte-de-examen-trebuie-să-înveți',
    description: [
      'Pregătirea pentru un examen necesită planificare și disciplină. Întrebarea „Cu cât timp înainte de examen trebuie să înveți?” este una frecvent întâlnită printre studenți și răspunsul variază în funcție de mai mulți factori, inclusiv dificultatea subiectului, volumul de material de studiat și stilul individual de învățare.',
      'Pentru majoritatea studenților, o abordare recomandată este să înceapă să studieze cu cel puțin câteva săptămâni înainte de data examenului. Aceasta permite repartizarea uniformă a materiei pe o perioadă mai lungă de timp, evitând astfel învățarea sub presiune în ultimele zile. Cercetările arată că învățarea distribuită, adică repetarea informației la intervale regulate, este mult mai eficientă decât învățarea intensă pe termen scurt. Acest proces permite consolidarea cunoștințelor și înțelegerea profundă a materialului.',
      'Un plan de studiu eficient ar trebui să includă revizuirea materialului, exersarea prin teste practice și sesiuni de discuții sau grupuri de studiu. De asemenea, este important să fie alocate pauze regulate pentru a preveni epuizarea mentală și pentru a menține concentrarea. Studenții ar trebui să-și organizeze timpul de studiu ținând cont de punctele lor forte și de ariile unde au nevoie de îmbunătățiri suplimentare.',
      'Pentru unele examene complexe sau foarte importante, poate fi necesar să înceapă pregătirea cu câteva luni înainte. De exemplu, pregătirea pentru examenele de admitere la facultate sau pentru certificările profesionale poate necesita o perioadă extinsă de studiu intensiv.',
      'În concluzie, nu există un răspuns universal valabil pentru toată lumea. Cu cât începi să te pregătești mai devreme, cu atât vei avea mai mult timp să acoperi întreaga materie, să clarifici neclaritățile și să te pregătești mental pentru examen. Planificarea timpurie, studierea constantă și utilizarea unor tehnici de învățare eficace sunt cheia succesului la examen.',
    ],
  },
  {
    imageSmall: './../images/articles/article--2-small.webp',
    imageLarge: './../images/articles/article--2-large.webp',
    title: 'Care sunt cele mai bune metode de a învăța?',
    slug: 'care-sunt-cele-mai-bune-metode-de-a-învăța',
    description: [
      'Există numeroase metode eficiente de învățare, iar alegerea celei mai potrivite depinde de stilul individual de învățare și de materialul care trebuie asimilat. Iată câteva dintre cele mai eficiente metode de a învăța:',
      '1.	Învățarea activă: Implică participarea activă în procesul de învățare, prin discutarea materialului, punerea întrebărilor și explicarea conceptelor altor persoane. Aceasta ajută la consolidarea cunoștințelor și la o mai bună înțelegere a materialului.',
      '2.	Testarea periodică: Auto-testarea regulată ajută la consolidarea memoriei și la identificarea zonelor care necesită mai multă atenție. Testele practice sunt un instrument eficient pentru a evalua nivelul de cunoștințe și pentru a exersa aplicarea acestora.',
      '3.	Învățarea distribuită: Studiați materialul în sesiuni scurte și frecvente, mai degrabă decât într-o sesiune lungă și intensă. Aceasta tehnică, cunoscută și sub numele de “învățare spațiată”, este mult mai eficientă pentru reținerea pe termen lung a informațiilor.',
      '4.	Crearea de scheme și hărți mentale: Vizualizarea informației prin hărți mentale sau scheme poate ajuta la organizarea și structurarea materialului într-un mod logic și ușor de reținut. Aceasta tehnică este deosebit de utilă pentru învățarea concepțiilor complexe și a relațiilor dintre ele.',
      '5.	Învățarea prin predare: Încercați să învățați materialul astfel încât să îl puteți explica altcuiva. Procesul de predare obligă să înțelegeți profund materialul și să clarificați orice neclarități.',
      '6.	Folosirea tehnicilor mnemotehnice: Acestea includ trucuri de memorie, cum ar fi acronime, rime sau asocieri vizuale, care ajută la reținerea informațiilor dificile.',
      '7.	Alternarea subiectelor: În loc să studiați un singur subiect pentru o perioadă lungă de timp, alternați între subiecte diferite. Această tehnică ajută la menținerea interesului și la îmbunătățirea abilității de a face conexiuni între concepte diferite.',
      '8.	Pauze regulate și odihnă adecvată: Studiul intens fără pauze poate duce la epuizare mentală. Este esențial să includeți pauze regulate și să aveți grijă de odihna și somnul adecvat pentru a asigura o capacitate maximă de concentrare și asimilare a informațiilor.',
      'În concluzie, cele mai bune metode de învățare combină tehnici variate care se potrivesc stilului individual și tipului de material de studiat. Învățarea activă, testarea periodică și organizarea eficientă a timpului de studiu sunt esențiale pentru succesul academic.',
    ],
  },
  {
    imageSmall: './../images/articles/article--3-small.webp',
    imageLarge: './../images/articles/article--3-large.webp',
    title: 'De ce este importantă educația?',
    slug: 'de-ce-este-importantă-educația',
    description: [
      'Educația este esențială pentru dezvoltarea individuală și societală, având un impact profund asupra vieților oamenilor și a comunităților. Iată câteva motive principale pentru care educația este importantă:',
      '1.	Dezvoltare personală: Educația oferă cunoștințele și abilitățile necesare pentru dezvoltarea intelectuală și emoțională. Prin educație, indivizii își pot descoperi pasiunile, își pot dezvolta gândirea critică și își pot cultiva talentele. Aceasta contribuie la formarea unei identități puternice și la îmbunătățirea calității vieții.',
      '2.	Oportunități economice: Educația este un factor crucial în obținerea unui loc de muncă bine plătit și stabil. Persoanele cu un nivel mai înalt de educație au acces la mai multe oportunități de angajare și potențial de câștig. Aceasta contribuie la reducerea sărăciei și la îmbunătățirea standardului de viață.',
      '3.	Progres social: Educația promovează egalitatea și justiția socială. Ea poate reduce discriminarea bazată pe gen, rasă sau statut socio-economic, oferind tuturor indivizilor șansa de a-și atinge potențialul. Educația joacă un rol esențial în crearea unei societăți mai incluzive și mai echitabile.',
      '4.	Dezvoltare civică: O educație solidă formează cetățeni informați și responsabili, capabili să contribuie activ la viața comunității lor. Educația civică și politică încurajează participarea la procesele democratice și promovează înțelegerea drepturilor și responsabilităților cetățenești.',
      '5.	Inovație și progres tehnologic: Educația stimulează cercetarea și inovația. O societate bine educată este mai capabilă să dezvolte soluții inovatoare pentru provocările globale, cum ar fi schimbările climatice, bolile și crizele economice. Progresul în știință și tehnologie depinde în mare măsură de educația avansată.',
      '6.	Sănătate și bunăstare: Educația contribuie la o mai bună înțelegere a sănătății și a bunăstării. Persoanele educate sunt mai conștiente de practicile sănătoase și de importanța prevenției, ceea ce duce la o calitate mai bună a vieții și la o speranță de viață mai mare.',
      '7.	Dezvoltare economică și sustenabilitate: Educația este esențială pentru dezvoltarea economică durabilă. O forță de muncă educată este mai productivă și mai inovatoare, ceea ce conduce la creștere economică. De asemenea, educația promovează conștientizarea problemelor de mediu și a practicilor sustenabile.',
      'În concluzie, educația este un pilon fundamental al dezvoltării individuale și colective. Ea îmbunătățește calitatea vieții, promovează egalitatea, stimulează inovația și asigură progresul economic și social. Investiția în educație este esențială pentru construirea unui viitor mai bun pentru toți.',
    ],
  },
  {
    imageSmall: './../images/articles/article--4-small.webp',
    imageLarge: './../images/articles/article--4-large.webp',
    title: 'Cum să îți gestionezi timpul eficient în timpul anului școlar?',
    slug: 'cum-să-îți-gestionezi-timpul-eficient-în-timpul-anului-școlar',
    description: [
      'Gestionarea eficientă a timpului în timpul anului școlar este esențială pentru succesul academic și pentru menținerea unui echilibru sănătos între studiu și activitățile personale. Iată câteva strategii care te pot ajuta să îți organizezi timpul în mod eficient.',
      '1. Planifică-ți activitățile: Crearea unui program săptămânal este un prim pas crucial. Notează orele de curs, perioadele dedicate studiului, activitățile extracurriculare și timpul liber. Folosirea unei agende sau a unei aplicații de planificare te poate ajuta să ai o imagine clară asupra sarcinilor și responsabilităților tale.',
      '2. Prioritizează sarcinile: Nu toate activitățile au aceeași importanță. Învață să îți stabilești prioritățile, concentrându-te mai întâi pe sarcinile esențiale sau cu termene limită stricte. Tehnica Eisenhower, care împarte sarcinile în patru categorii (urgente și importante, importante dar nu urgente, urgente dar nu importante, nici urgente nici importante), poate fi de mare ajutor.',
      '3. Stabilește obiective clare: Setarea unor obiective zilnice, săptămânale și lunare te poate motiva și te poate ajuta să îți menții direcția. Obiectivele ar trebui să fie specifice, măsurabile, realizabile, relevante și limitate în timp (SMART).',
      '4. Evită procrastinarea: Procrastinarea este unul dintre cei mai mari dușmani ai gestionării eficiente a timpului. Împarte sarcinile mari în activități mai mici și începe cu pași mici. Metoda Pomodoro, care presupune sesiuni de lucru de 25 de minute urmate de pauze scurte, poate fi eficientă pentru a te menține concentrat.',
      '5. Alocă timp pentru odihnă și recreere: Nu neglija importanța pauzelor și a timpului liber. Odihna adecvată și activitățile recreative îți vor reîncărca bateriile și îți vor îmbunătăți productivitatea.',
      '6. Revizuiește-ți planul regulat: Flexibilitatea este cheia unei bune gestionări a timpului. Evaluează periodic eficiența planului tău și fă ajustări în funcție de necesități și de schimbările apărute.',
      'Prin implementarea acestor strategii, vei putea să îți gestionezi mai eficient timpul în timpul anului școlar, să reduci stresul și să îți îmbunătățești performanțele academice. Organizarea și disciplina sunt fundamentale pentru atingerea succesului și menținerea unui echilibru sănătos între viața academică și cea personală.',
    ],
  },
  {
    imageSmall: './../images/articles/article--5-small.webp',
    imageLarge: './../images/articles/article--5-large.webp',
    title: 'Strategii pentru a te motiva să înveți',
    slug: 'strategii-pentru-a-te-motiva-să-înveți',
    description: [
      'Motivația de a învăța este crucială pentru succesul academic, dar poate fi uneori dificil de menținut, mai ales atunci când subiectele sunt provocatoare sau interesul scade. Iată câteva strategii eficiente pentru a te motiva să înveți:',
      '1. Stabilește obiective clare și realiste: Definirea unor obiective specifice, măsurabile și realizabile te poate ajuta să îți menții concentrarea. De exemplu, în loc să îți spui „Vreau să învăț matematica”, poți spune „Vreau să rezolv 10 probleme de matematică astăzi”. Astfel, vei avea un scop concret care îți va ghida eforturile.',
      '2. Creează un program de studiu: Organizarea timpului într-un mod structurat te poate ajuta să eviți procrastinarea. Stabilește perioade regulate de studiu și respectă-le ca și cum ar fi întâlniri importante. Utilizarea unei agende sau a unei aplicații de planificare poate fi foarte utilă în acest sens.',
      '3. Găsește un loc de studiu propice: Mediul în care înveți joacă un rol important în menținerea motivației. Alege un loc liniștit, bine iluminat și fără distrageri. Un spațiu de studiu organizat și plăcut poate îmbunătăți considerabil concentrarea și eficiența.',
      '4. Utilizează tehnici de învățare variate: Diversificarea metodelor de învățare poate menține interesul ridicat. Încearcă să combini cititul cu luarea de notițe, vizionarea de videoclipuri educative, realizarea de hărți mentale și discuțiile în grupuri de studiu. Aceste tehnici variate pot face procesul de învățare mai dinamic și mai captivant.',
      '5. Recompensează-te: Stabilește un sistem de recompense pentru atingerea obiectivelor de studiu. Acestea pot fi mici recompense, cum ar fi o pauză, un episod din serialul preferat sau o gustare delicioasă. Recompensele te pot motiva să îți îndeplinești sarcinile și să îți menții entuziasmul.',
      '6. Conectează învățarea cu interesele tale: Găsește modalități de a face legătura între materialul de studiat și interesele tale personale. De exemplu, dacă îți place tehnologia, explorează cum se aplică principiile matematice sau științifice în domeniul tech. Aceasta poate crește relevanța și interesul pentru subiect.',
      '7. Fă pauze regulate: Studiul intensiv fără pauze poate duce la epuizare și scăderea motivației. Aplicarea tehnicii Pomodoro, care presupune sesiuni de lucru de 25 de minute urmate de pauze scurte, poate îmbunătăți productivitatea și menține nivelul de energie ridicat.',
      '8. Înconjoară-te de oameni pozitivi și motivați: Un grup de studiu cu colegi entuziasmați și dedicați poate fi o sursă excelentă de motivație. Împărtășirea obiectivelor și provocărilor cu alții poate oferi sprijin și inspirație reciprocă.',
      'Prin implementarea acestor strategii, poți crește motivația de a învăța și îți poți îmbunătăți performanțele academice. Motivația este un element esențial pentru succesul școlar, iar menținerea acesteia necesită eforturi constante și planificare atentă.',
    ],
  },
  {
    imageSmall: './../images/articles/article--6-small.webp',
    imageLarge: './../images/articles/article--6-large.webp',
    title: 'Cum să reduci stresul înainte și în timpul examenelor?',
    slug: 'cum-să-reduci-stresul-înainte-și-în-timpul-examenelor',
    description: [
      'Stresul înainte și în timpul examenelor este o problemă comună pentru mulți studenți, dar există strategii eficiente pentru a-l gestiona și a-l reduce. Iată câteva sfaturi utile:',
      '1. Planifică și organizează-te: Un plan de studiu bine structurat te poate ajuta să te simți mai pregătit și mai încrezător. Împarte materialul de învățat în segmente mici și stabilește obiective zilnice sau săptămânale. Astfel, vei evita suprasolicitarea și vei avea o abordare sistematică a învățării.',
      '2. Practică tehnici de relaxare: Exercițiile de respirație profundă, meditația și yoga sunt metode excelente pentru a reduce stresul. Aceste practici te ajută să te relaxezi, să îți calmezi mintea și să îți îmbunătățești concentrarea.',
      '3. Fă pauze regulate: Studiul intens fără pauze poate duce la epuizare. Utilizează tehnica Pomodoro, care presupune sesiuni de studiu de 25 de minute urmate de pauze scurte, pentru a menține un echilibru sănătos între muncă și relaxare. Pauzele regulate te ajută să îți reîncarci bateriile și să previi oboseala mentală.',
      '4. Menține un stil de viață sănătos: Alimentația echilibrată, somnul adecvat și exercițiile fizice regulate joacă un rol crucial în gestionarea stresului. Evită alimentele nesănătoase și cofeina în exces, deoarece acestea pot agrava anxietatea. Asigură-te că dormi suficient și faci mișcare, pentru a-ți menține corpul și mintea în formă.',
      '5. Fii pozitiv și încrezător: Atitudinea pozitivă și auto-încurajarea sunt esențiale pentru a face față stresului. Reamintește-ți de succesele anterioare și de abilitățile tale. Înlocuiește gândurile negative cu afirmații pozitive și concentrează-te pe aspectele pe care le poți controla.',
      '6. Exersează prin simulări de examen: Practicarea testelor sau a examenelor simulate te poate ajuta să te familiarizezi cu formatul și cerințele examenului real. Aceasta îți va reduce anxietatea și îți va crește încrederea în abilitățile tale.',
      '7. Comunică și cere ajutor: Nu ezita să vorbești cu profesorii, colegii sau familia despre stresul pe care îl simți. Sprijinul social este esențial în perioadele dificile. Participarea la grupuri de studiu sau sesiuni de mentorat poate oferi un plus de claritate și înțelegere a materialului.',
      '8. Evită supraîncărcarea de informații: În ultimele zile înainte de examen, evită să înveți materiale noi și concentrează-te pe revizuirea și consolidarea cunoștințelor deja dobândite. Supraîncărcarea cu informații noi poate crește nivelul de stres și confuzie.',
      '9. Pregătește-te logistic pentru examen: Asigură-te că ai toate materialele necesare pentru examen și cunoști locația și ora exactă. Planifică-ți drumul către locul examenului, astfel încât să ajungi cu suficient timp înainte și să eviți orice stres suplimentar cauzat de întârziere.',
      'Implementând aceste strategii, poți reduce semnificativ nivelul de stres înainte și în timpul examenelor. O abordare calmă și organizată te va ajuta să îți maximizezi performanțele și să abordezi examenele cu încredere.',
    ],
  },
  {
    imageSmall: './../images/articles/article--7-small.webp',
    imageLarge: './../images/articles/article--7-large.webp',
    title: 'De ce este important sa studiezi?',
    slug: 'de-ce-este-important-sa-studiezi',
    description: [
      'Studiul este o arma valoroasa in viata unei persoane. Datorita studiului, putem asimila notiuni in mod constant care ne permit sa ne dezvoltam gandurile si abilitatile.',
      'Pentru fiecare persoana, studiul are sens si importanta diferita in viata sa. Este important sa studiem pentru ca, doar studiind si invatand noi cunostinte ne putem dezvolta mintea, putem intelege originile noastre: cine suntem, cum am ajuns aici, cum am fost creati, cum a fost creat universul. Putem intelege cultura noastra: istoria vremurilor trecute, revolutile si luptele pentru putere care alcatuiesc societatea. Mai presus de toate, putem gasi sensul identitatii noastre, ne extindem gandurile si nu ramane doar in elementele de baza ci ne dezvoltam ca indivizi.',
    ],
  },
  {
    imageSmall: './../images/articles/article--8-small.webp',
    imageLarge: './../images/articles/article--8-large.webp',
    title: 'Câteva metode de învațare',
    slug: 'cateva-metode-de-invatare',
    description: [
      'Cu cat timp inainte de examen trebuie sa incepi sa inveti? Cand ar trebui sa incepi sa studiezi pentru un examen? Stiinta raspunde: cat mai devreme!  Informatia proaspata este uitata rapid, daca nu este repetata deloc in primele 24 de ore de la invatarea ei.',
      '1. Invata cu pixul si foaia langa tine. Sublinierea, evidentierea in culori diferite sau scrierea de rezumate pot fi eficiente in unele cazuri si pentru anumite persoane.',
      '2. Opreste-ti notificarile sau pune-ti telefonul in Mod Avion. Verificarea constanta a notificarilor iti incetineste procesul de invatare si te face sa-ti pierzi concentrarea.',
      '3.Locatii variate de invatare. Specialistii recomanda ca sesiunile de invatare sa se desfasoare in locatii diferite de fiecare data – de exemplu in parc, la biblioteca, in camera ta. Cu ajutorul unor studii in domeniu s-a descoperit ca schimbarea locatiei in care invatam forteaza creierul sa creeze noi asocieri si noi conexiuni catre aceeasi informatie.',
      '4. Incearca tehnica Pomodoro. 20 de minute sunt alocate invatarii, apoi iei o pauza de 5 minute. Dupa ce completezi acest ciclu de 4 ori, ia o pauza de 15 minute si reia de la inceput: 20 - 5 - 20 - 5 - 20 - 5 - 20 - 5 + 15',
      '5. Invatatul cu voce tare. Citind informatia cu voce tare vei retine informatia mai usor. De ce? Pentru ca o si auzi, o si vezi. In plus, daca ai posibilitatea sa discuti cu cineva despre ceea ce inveti, cu atat mai bine.',
      '6. Cocktail de acizi grasi. Acizii grasi Omega-3 si Omega-6 pot aduce performante bune. Omega-3 se obtine din nuci, ulei de masline sau peste si au un efect benefic asupra creierului. Un studiu referitor la efectul acestor acizi grasi a demonstrat ca elevii care au consumat Omega-3 si Omega-6 inainte de examen s-au simtit mai calmi si s-au putut concentra mai bine.',
    ],
  },
];

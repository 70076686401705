import './formular-anpc.styles.scss';
import { AiFillFileText } from 'react-icons/ai';
import Button from '../../components/Button/Button';

const FormularAnpc = () => {
  return (
    <div className='formular-anpc'>
      <div className='formular-anpc__content'>
        <h2>
          Donează gratuit <span>3.5%</span> din impozitul pe salariu pentru
          copiii Asociației INVATA PENTRU TINE!
        </h2>
        <p>
          <AiFillFileText className='formular-anpc__icon' /> Completați
          formularul 230 pentru copiii Asociației INVATA PENTRU TINE și ajutati
          acesti copii sa beneficieze GRATUIT de ore suplimentare de studiu la
          materiile unde sunt probleme sau pentru a se pregati in vederea
          sustinerii examenelor importante din viata lor (examenul de capacitate
          sau bacalaureat).
        </p>
        <p>
          <AiFillFileText className='formular-anpc__icon' /> Prin completarea
          acestui formular, solicitați statului roman sa redirectioneze catre
          Asociatia INVATA PENTRU TINE 3.5% din impozitul pe salariu pe care
          angajatorul dumneavoastra oricum il plateste obligatoriu luna de luna
          la stat.
        </p>
      </div>

      <a
        href='./../documents/FORMULAR 230 ASOCIATIA INVATA PENTRU TINE.pdf'
        download='FORMULAR 230 ASOCIATIA INVATA PENTRU TINE.pdf'
        style={{ textDecoration: 'none' }}>
        <Button type='cta'>Descarcă formularul 230</Button>
      </a>
    </div>
  );
};

export default FormularAnpc;

import './error-404.styles.scss';

const Error404 = ({ message, type }) => {
  return (
    <div className={`error-404 error-404__${type}`}>
      <h1>404</h1>
      <h3>{message}</h3>
    </div>
  );
};

export default Error404;

import { createContext, useContext, useEffect, useState } from 'react';

import notificationsTexts from '../utils/notifications.json';

const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [activePanel, setActivePanel] = useState(false);
  const [activeBtn, setActiveBtn] = useState(() => {
    const itemStr = localStorage.getItem('activeBtn');
    if (!itemStr) {
      return true;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // Check if the current time is past the item's expiration time
    if (now.getTime() > item.expiry) {
      localStorage.removeItem('activeBtn');
      return true;
    }
    return item.value;
  });
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const now = new Date();
    const item = {
      value: activeBtn,
      //   expiry: now.getTime() + 1800000, // Check every half hour
      expiry: now.getTime() + 60000, // Check every minute
    };
    localStorage.setItem('activeBtn', JSON.stringify(item));
  }, [activeBtn]);

  useEffect(() => {
    const filterActiveNotifications = () => {
      const currentNotifications = notificationsTexts.filter((notification) => {
        const currentDate = new Date();
        const expirationDate = new Date(notification.expirationDate);

        return expirationDate > currentDate;
      });

      return currentNotifications;
    };

    const activeNotifications = filterActiveNotifications();

    const assignNewIds = (notifications) => {
      return notifications.map((notification, index) => ({
        ...notification,
        id: index + 1,
      }));
    };

    const newNotifications = assignNewIds(activeNotifications);
    setNotifications(newNotifications);

    if (activeNotifications.length === 0) {
      setActiveBtn(false);
    }
  }, []);

  return (
    <NotificationsContext.Provider
      value={{
        activePanel,
        setActivePanel,
        activeBtn,
        setActiveBtn,
        notifications,
      }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => {
  return useContext(NotificationsContext);
};

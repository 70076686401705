import './article-page.scss';
import { useParams } from 'react-router-dom';
import { articles } from '../../utils/articles';
import NavigationBar from '../../components/Navigation/NavigationBar';
import LinkComponent from '../../components/Link/Link.component.jsx';
import FooterUI from '../../ui/footer/footer.ui';
import Error404 from '../../components/404/Error404.components';

const ArticlePage = () => {
  const { slug } = useParams();
  const article = articles.find((article) => article.slug === slug);

  return (
    <>
      <NavigationBar />
      <main className='article-page'>
        <LinkComponent to={`/`} classStyle={'left'} children={'Acasă'} />
        {article ? (
          <div className='article-page__container'>
            <div className='article-page__welcome'>
              <img
                className='article-page__image'
                src={article.imageLarge}
                alt={article.title}
              />
              <h2 className='article-page__title'>{article.title}</h2>
            </div>

            <div className='article-page__content'>
              {article.description.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
          </div>
        ) : (
          <Error404 message='Acest articolul există!' type={'small'} />
        )}
        <LinkComponent
          to={`/articole`}
          classStyle={'left'}
          children={'Înapoi la articole'}
        />
      </main>
      <FooterUI />
    </>
  );
};

export default ArticlePage;

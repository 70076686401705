import './navigation-bar.scss';
import { Link } from 'react-router-dom';
import { Logo } from '../../utils/imagesPath.js';

const NavigationBar = () => {
  const { logo } = Logo;

  return (
    <div className='navigation-bar'>
      <Link to='/' className='navigation-bar__link'>
        <img src={logo} alt='Logo' className='navigation-bar__logo' />
        <h2 className='navigation-bar__title'>Învață pentru tine</h2>
      </Link>
    </div>
  );
};

export default NavigationBar;

export const Logo = {
  logo: './../logo.webp',
};

export const Images = {
  mission: './../images/backgrounds/mission-v4.webp',
  missionLarge: './../images/backgrounds/mission-v4-large.webp',
  vision1: './../images/backgrounds/vision-1.webp',
  vision2: './../images/backgrounds/vision-2.webp',
  vision3: './../images/backgrounds/vision-3.webp',
  charitySmall: './../images/backgrounds/charity-small.webp',
  charityBig: './../images/backgrounds/charity-big.webp',
  anpcSAL: './../images/ANPC/anpc-sal.png',
  anpcSOL: './../images/ANPC/anpc-sol.png',
};

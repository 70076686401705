import './partners.styles.scss';

import partners from '../../utils/partners.json';

const PartnersUI = () => {
  return (
    <div className='partners-ui'>
      <h2 className='h h--xl'>Parteneri si sponsori</h2>
      <h2>
        <span>Împreună</span> putem face mai mult!
      </h2>
      <div className='partners-ui__logos'>
        {partners.map((partner) => {
          return (
            <a
              key={partner.id}
              href={partner.link}
              target='_blank'
              rel='noreferrer'>
              <img src={partner.img} alt={partner.alt} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default PartnersUI;

import './Reviews.scss';

const Reviews = () => {
  return (
    <div className='reviews'>
      <h2 className='h h--xl'>
        Recenzii de la <span>părinți</span>
      </h2>
      <div className='reviews__container'>
        <div className='reviews__box'>
          <p>
            "Pasim cu curaj catre un nou an scolar. Curaj pentru ca am intalnit
            in cadrul Asociatiei Invata pentru tine, oameni deosebiti, ce cu
            multa rabdare si daruire, au reusit sa insufle copiilor mei dragul
            de a invata, de cunoastere. Va multumim ca existati, si ca va
            straduiti sa ne oferiti suportul de care avem atata nevoie. Recomand
            cu caldura tuturor celor interesati de cursuri predate frumos,
            energic si cu continut de calitate, sa apeleze la Asociatie. "
          </p>
          <div className='reviews__box__name'>
            <p>Florica M.</p>
          </div>
        </div>
        <div className='reviews__box'>
          <p>
            "Descoperirea Asociatiei Invata pentru tine este o experiență
            minunată pentru noi. Felicitări! Vă admir profesionalismul si
            daruirea fata de copii. Datorita profesorilor dedicati copilul meu
            evolueaza intr-un mediu placut. Multumesc Asociatia "Invata pentru
            tine" .”
          </p>
          <div className='reviews__box__name'>
            <p>Daniela S.</p>
          </div>
        </div>
        <div className='reviews__box'>
          <p>
            “ Mulțumim pentru implicare și susținerea unei astfel de activități.
            Recomandăm cu toată încrederea serviciile oferite; am întâlnit
            profesori răbdători, dedicați și inspiraționali, care au știut să
            motiveze și să sprijine fiecare elev. “
          </p>
          <div className='reviews__box__name'>
            <p>Alexandra B.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;

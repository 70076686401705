export const scope = [
  {
    id: 1,
    text: 'Susținerea (financiară și nu doar) a orelor necesare de pregătire în vederea examenelor, sau îmbunătățirii nivelului de studiu  prin ore individuale de studiu focusate pe nevoia elevului.',
  },
  {
    id: 2,
    text: 'Promovarea și susținerea procesului de învățare, instruire și educație a copiiilor, tinerilor',
  },
  {
    id: 3,
    text: 'Organizarea de activități educationale și de dezvoltare specifice vârstei și activități de pregatire',
  },
  {
    id: 4,
    text: 'Organizarea și/sau participarea la ateliere, workshopuri, cursuri pe diverse teme.',
  },
  {
    id: 5,
    text: 'Desfasurarea de activități, proiecte, metode, procedee și programe care au ca rezultat îmbunătățirea procesului de învățare',
  },
  {
    id: 6,
    text: 'Realizarea de activități educaționale a copiiilor, tinerilor privind calitatea vieții, prin îmbunătățirea capacității de învățare',
  },
];

import './not-found-page.scss';
import NavigationBar from '../../components/Navigation/NavigationBar';
import FooterUI from '../../ui/footer/footer.ui';
import Error404 from '../../components/404/Error404.components';

const NotFoundPage = () => {
  return (
    <>
      <NavigationBar />
      <main className='not-found-page'>
        <Error404 message='Această pagină nu există!' type={'big'} />
      </main>
      <FooterUI />
    </>
  );
};

export default NotFoundPage;

import './suportPanel.styles.scss';

import { Images } from '../../utils/imagesPath';
import CounterItem from '../../components/Counter-Item/CounterItem';

const SuportPanel = () => {
  const { charitySmall, charityBig } = Images;

  return (
    <div className='suport-panel'>
      <div className='suport-panel__images'>
        <img
          src={charitySmall}
          alt='misiune'
          className='suport-panel__images--small'
        />
        <img
          src={charityBig}
          alt='viziune1'
          className='suport-panel__images--big'
        />
      </div>
      <div className='suport-panel__texts'>
        <h2>
          Suportul vostru ne ajută să oferim o <span>educație</span> de calitate
        </h2>
        <p>
          În fiecare zi, copiii noștri se confruntă cu provocări care le
          testează încrederea și determinarea. Dar cu ajutorul nostru, ei pot
          depăși orice obstacol și pot avea o viață mai bună prin educație.
        </p>

        <div className='suport-panel__texts__box'>
          {/* <CounterItem target={0} label='Donatii' /> */}
          <CounterItem target={50} label='Elevi' />
          <CounterItem target={25} label='Voluntari' />
        </div>
      </div>
    </div>
  );
};

export default SuportPanel;

import './news-panel.styles.scss';
import { useCallback, useEffect, useState } from 'react';
import { AiFillCloseSquare } from 'react-icons/ai';

import { useNotifications } from '../../context/notifications.context';

const NewsPanel = () => {
  const { setActivePanel, notifications } = useNotifications();
  const [noMessage, setNoMessage] = useState('');
  const [message, setMessage] = useState(false);
  const [idx, setIdx] = useState(null);

  const handleClick = useCallback(() => {
    setActivePanel(false);
  }, [setActivePanel]);

  const selectedMessage = (id) => {
    const selectedMessage = notifications.find((el) => el.id === id);

    let newIdx =
      selectedMessage.id <= notifications.length
        ? selectedMessage.id - 1
        : (selectedMessage.id - 1) % notifications.length;

    setIdx(newIdx);
    setMessage(true);
  };

  useEffect(() => {
    if (notifications.length === 0) {
      setNoMessage('Nu aveți notificări noi.');
    }
  }, [notifications]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleClick();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [handleClick]);

  return (
    <div className='news-panel'>
      <div className='news-panel__navbar'>
        <button className='news-panel__button' onClick={() => handleClick()}>
          <AiFillCloseSquare className='news-panel__icon' />
        </button>
      </div>
      <div className='news-panel__container'>
        {!message &&
          notifications.map((el, idx) => (
            <button
              className='news-panel__largeButton'
              key={el.id}
              onClick={() => selectedMessage(el.id)}>{`${idx + 1}. ${
              el.title
            }`}</button>
          ))}
        {message && (
          <div className='news-panel__bord'>
            <h3>{notifications[idx].title}</h3>
            <p>{notifications[idx].description}</p>
            <button
              className='news-panel__smallButton'
              onClick={() => setMessage(false)}>
              ← Înapoi
            </button>
          </div>
        )}
        {noMessage && (
          <div className='news-panel__box'>
            <h3>{noMessage}</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsPanel;

import './articles-page.scss';
import NavigationBar from '../../components/Navigation/NavigationBar';
import FooterUI from '../../ui/footer/footer.ui';
import LinkComponent from '../../components/Link/Link.component.jsx';
import { articles } from '../../utils/articles';
import ArticleCard from '../../components/Article-card/ArticleCard.component';

export const ArticlesPage = () => {
  return (
    <>
      <NavigationBar />
      <main className='articles-page'>
        <LinkComponent to={`/`} classStyle={'left'} children={'Acasă'} />
        <div className='articles-page__container'>
          {articles.map((article) => {
            return <ArticleCard key={article.title} article={article} />;
          })}
        </div>
      </main>
      <FooterUI />
    </>
  );
};

export default ArticlesPage;

import './news-button.styles.scss';
import { useNotifications } from '../../context/notifications.context';

import { AiOutlineBell } from 'react-icons/ai';

const NewsButton = () => {
  const { setActivePanel, activeBtn, setActiveBtn, notifications } =
    useNotifications();

  const handleClick = () => {
    setActivePanel(true);
    setActiveBtn(false);
  };

  return (
    <div className='news-button'>
      <button
        onClick={handleClick}
        className={`news-button__button news-button__button--${
          activeBtn && 'active'
        }`}>
        <AiOutlineBell
          className={`news-button__icon news-button__icon--${
            activeBtn && 'active'
          }`}
        />
      </button>
      {activeBtn && (
        <div className='news-button__text'>
          <p>{notifications.length}</p>
        </div>
      )}
    </div>
  );
};

export default NewsButton;

import Button from '../Button/Button';
import './DonationPanel.scss';
import { AiOutlineClose } from 'react-icons/ai';

const DonationPanel = ({ info, handleInfo, handlePayment, closePanel }) => {
  return (
    <div className='donation-panel'>
      {info ? (
        <>
          <button className='donation-panel__closeBTN' onClick={closePanel}>
            <AiOutlineClose />
          </button>
          <div className='donation-panel__header'>
            <h2>Asociația ÎNVAȚĂ PENTRU TINE</h2>
            <h3> vine în sprijinul copiilor care își doresc să învețe</h3>
          </div>
        </>
      ) : (
        <h2>Și tu poți contribui la schimbarea vieții unui copil!</h2>
      )}
      <div className='donation-panel__container'>
        {info && (
          <>
            <div className='donation-panel__box'>
              <h4>Transfer bancar</h4>
              <p>
                <span>C.I.F:</span> 50152285
              </p>
              <p>
                <span>RON:</span> RO07BTRLRONCRT0CS4343901
              </p>
              <p>
                <span>EUR:</span> RO51BTRLEURCRT0CS4343901
              </p>
              <p>
                <span>GBP:</span> RO22BTRLGBPCRT0CS4343901
              </p>
            </div>
          </>
        )}

        {info && (
          <>
            <div className='donation-panel__box'>
              <h4>Plata cu cardul</h4>
              <Button type='cta' onClick={handlePayment}>
                Donează acum
              </Button>
            </div>

            <p>
              *Pentru donații mai mari de 1000 RON vă rugăm să ne{' '}
              <button
                className='button-link'
                onClick={() =>
                  document
                    .getElementById('footer')
                    .scrollIntoView({ behavior: 'smooth' })
                }>
                contactați
              </button>
            </p>
          </>
        )}

        {!info && (
          <Button type='cta' onClick={handleInfo}>
            Donează
          </Button>
        )}
      </div>
    </div>
  );
};

export default DonationPanel;

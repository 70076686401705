import './vision.styles.scss';

import { Images } from '../../utils/imagesPath.js';
import LinkComponent from '../../components/Link/Link.component.jsx';

const VisionUI = () => {
  const { vision1, vision2, vision3 } = Images;

  return (
    <div className='vision-ui'>
      <div className='vision-ui__image'>
        <div className='vision-ui__image--left'>
          <img
            src={vision1}
            alt='A child with a woman in a classroom'
            className='vision-ui__image--img vision-ui__image--img__big'
          />
        </div>
        <div className='vision-ui__image--right'>
          <img
            src={vision2}
            alt='A child with a woman in a classroom'
            className='vision-ui__image--img vision-ui__image--img__small vision-ui__image--img__small--up'
          />
          <img
            src={vision3}
            alt='A child with a woman in a classroom'
            className='vision-ui__image--img vision-ui__image--img__small vision-ui__image--img__small--down'
          />
        </div>
      </div>
      <div className='vision-ui__content'>
        <h2 className='h h--xl'>Viziunea noastră</h2>
        <p>
          Este de a oferi o educație de calitate pentru toți copiii, indiferent
          de situația lor socială sau financiară.
        </p>
        <LinkComponent
          to={'/viziunea-noastra'}
          classStyle={'right'}
          children={'Află mai multe'}
        />
      </div>
    </div>
  );
};

export default VisionUI;

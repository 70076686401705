import { Routes, Route } from 'react-router-dom';

// Contexts
import { NotificationsProvider } from './context/notifications.context';

import HomePage from './pages/Home/HomePage';
import MissionPage from './pages/Mission/MissionPage';
import VisionPage from './pages/Vision/VisionPage';
import ArticlesPage from './pages/Articles/ArticlesPage';
import ArticlePage from './pages/Article/ArticlePage';
import NotFoundPage from './pages/NotFound/NotFoundPage';

const App = () => {
  return (
    <>
      <NotificationsProvider>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/misiunea-noastra' element={<MissionPage />} />
          <Route path='/viziunea-noastra' element={<VisionPage />} />
          <Route path='/articole' element={<ArticlesPage />} />
          <Route path='/articole/:slug' element={<ArticlePage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </NotificationsProvider>
    </>
  );
};

export default App;
